<template>
  <div>
    <v-dialog v-model="dialog" width="70%">
      <template v-slot:activator="{ on, attrs }">
        <span class="link" v-bind="attrs" v-on="on">Clique aqui para visualizar o termo de aceite</span>
      </template>

      <v-card style="overflow-x:hidden">
        <v-card-title class="text-h5 lighten-2">
          Termos de aceite
        </v-card-title>

        <v-card-text class="mt-2">
          <VueShowdown class="mt-2" :markdown="terms" />
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <mf-action-buttons
            :primary-button="{
              text: 'Fechar',
              action: () => (dialog = false),
              isVisible: true
            }"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueShowdown } from 'vue-showdown'

export default {
  components: {
    VueShowdown
  },
  props: {
    terms: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
